export class HttpRequestService {
    static async request(url, options) {
        const response = await fetch(url, options);
        if (!response.ok) {
            console.log(`HTTP error! status: ${response.status}`);
        }
        return response;
    }
    static async getRequest(url, queryParams) {
        const response = await this.request(createUrlWithParams(url, queryParams), {});
        return response;
    }
    static async postRequest(url, data) {
        const response = await this.request(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        return response;
    }
}
export function createUrlWithParams(url, data) {
    const urlObject = new URL(url, window.location.origin);
    urlObject.search = new URLSearchParams(data).toString();
    return urlObject.href;
}
